html,
body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /*background-color: slategray;*/
}

#root {
}
canvas {
  width: 95%;
  height: 95%;
  touch-action: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#Heydo {
  position: absolute;
  top: 150px;
  left: 100px;
}

#cc {
  width: 400px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
